import { Placement, PositioningStrategy } from "@popperjs/core";
import React, { useState, useLayoutEffect, forwardRef, Ref, useEffect } from "react";
import { Manager, Reference, Popper, PopperChildrenProps } from "react-popper";
import maxSize from "popper-max-size-modifier";
import { cn } from "../../../utils/shorthands";
import * as scss from "./base-popover.scss";

export interface BasePopoverProps {
	/** The element from which to spawn the popover. The provided ref must be set on the element. */
	anchor: (ref: React.Ref<any>) => React.ReactChild; // eslint-disable-line @typescript-eslint/no-explicit-any
	/** The content of the popover  */
	popover:
		| React.ReactChild
		| ((p: Pick<PopperChildrenProps, "arrowProps" | "placement">) => React.ReactChild);
	/** Is the popover currently shown */
	showPopover: boolean;
	/** Preferred placement for the popover */
	placement?: Placement;
	/** Allowed placements for the popover when using an "auto" value for the "placement" prop */
	allowedAutoPlacements?: Placement[];
	/** Distance in px between the anchor and popover, defaults to 0 */
	offset?: number;
	/** Optional ID */
	id?: string;
	/** Key to control updates to the placement of the popover. Provide a different value when an update is desired */
	updateKeys?: React.DependencyList;
	/** Set aria-hidden */
	"aria-hidden"?: boolean;
	/** Position popover using fixed or absolute */
	strategy?: PositioningStrategy;
	/** Override z-index value (1500) specified in SCSS */
	zIndex?: number;
}

// This is a basic wrapper for Popper.js
export function BasePopover(props: BasePopoverProps): JSX.Element {
	const {
		anchor,
		placement = "auto",
		allowedAutoPlacements,
		popover,
		showPopover,
		offset = 0,
		id,
		updateKeys,
		"aria-hidden": ariaHidden,
		strategy = "fixed",
		zIndex,
	} = props;

	return (
		<Manager>
			<Reference>{({ ref }) => anchor(ref)}</Reference>
			<Popper
				placement={placement}
				strategy={strategy}
				modifiers={[
					{ name: "preventOverflow", options: { rootBoundary: "document" } },
					{ name: "eventListeners", options: { scroll: showPopover, resize: showPopover } }, //disable events when not shown
					{ name: "offset", options: { offset: [0, offset] } },
					{
						name: "computeStyles",
						options: {
							gpuAcceleration: false,
						},
					},
					{
						name: "flip",
						options: {
							allowedAutoPlacements: allowedAutoPlacements,
						},
					},
					{
						...maxSize,
						options: {
							padding: 10,
						},
					},
					{
						name: "applyMaxSize",
						enabled: true,
						phase: "beforeWrite",
						requires: ["maxSize"],
						fn({ state }) {
							const { height } = state.modifiersData.maxSize;
							state.styles.popper.maxHeight = `${height}px`;
						},
					},
				]}
			>
				{(popper) => (
					<InnerPopover
						{...popper}
						id={id}
						popover={popover}
						showPopover={showPopover}
						updateKeys={updateKeys}
						aria-hidden={ariaHidden}
						style={{ ...popper.style, zIndex: zIndex }} // merge zIndex prop with existing "style" from Popper
					/>
				)}
			</Popper>
		</Manager>
	);
}

type InnerPopoverProps = Pick<
	PopperChildrenProps,
	"style" | "update" | "arrowProps" | "placement"
> &
	Pick<BasePopoverProps, "popover" | "showPopover" | "id" | "updateKeys" | "aria-hidden">;

const InnerPopover = forwardRef((props: InnerPopoverProps, ref: Ref<HTMLDivElement>) => {
	const {
		popover,
		showPopover,
		style,
		update,
		arrowProps,
		placement,
		id,
		updateKeys = [],
		"aria-hidden": ariaHidden,
	} = props;
	//restState represents where we end up after a transition.
	//We need this since we want to not mount the popover when it is hidden due to performance issues,
	// but we do want a transition when opening/closing
	const [restState, setRestState] = useState(showPopover);
	/* show    rest
	   true  | true  ->  we are just visible (mount needed)
	   true  | false ->  we are fading in    (mount needed)
	   false | true  ->  we are fading out   (mount needed)
	   false | false ->  we are not visible  (mount not needed) */
	const shouldMount = showPopover || restState;

	useEffect(() => {
		if (showPopover && !restState) {
			setRestState(showPopover);
		}
		if (!showPopover && restState) {
			const t = setTimeout(() => setRestState(false), 200); //Timer needs to be slower then the transition (currently 0.1s)
			return () => clearTimeout(t);
		}
	}, [showPopover]);

	//Popper positioning depends on the size of the popover.
	//We schedule an update when we draw the popover since the size changes.
	useLayoutEffect(() => {
		update();
	}, [shouldMount, ...updateKeys]);

	return (
		<span
			data-component="popover"
			id={id}
			ref={ref}
			aria-hidden={ariaHidden}
			className={cn(
				scss.popperWrapper,
				showPopover && scss.show,
				!restState && scss.unMounted,
				"fancy-Popover"
			)}
			style={shouldMount ? style : undefined} //important to not set style for performance (triggers layout)
		>
			{shouldMount &&
				(typeof popover === "function" ? popover({ arrowProps, placement }) : popover)}
		</span>
	);
});
